/* ========================================================================
     Component: bootstrap-reset
 ========================================================================== */

// Use this file to reset simple bootstrap styles
// instead of modifying the core files
// Makes easy to update new framework versions
// --------------------------------------------------
@use "sass:math";

*:focus {
  outline: 0 !important;
}

a {
  outline: none !important;
  &.text-muted {
    &:hover,
    &:focus {
      color: darken($text-muted, 10%);
    }
  }
}

hr {
  border-top: 1px solid $gray-light;
}

// custom element
[data-now] {
  display: inline-block;
}

.badge {
  padding: 0.1875rem 0.4375rem;
  font-size: 0.75rem;
  border-radius: 0.625rem;
  &.badge-warning {
    color: #fff !important;
  }
  &.badge-secondary {
    background-color: $gray-dark;
    color: #fff;
  }
}

.list-group {
  line-height: 1.3;
  .list-group-item {
    padding: 10px;
    color: #555;
    &.active {
      color: #fff;
      .badge {
        background-color: #fff;
        color: $primary;
      }
    }
    .card > & {
      border: 0;
    }
  }
}

// .list-group+.card-footer {
//     border-top: 0;
// }

.page-header {
  padding-bottom: 0.59375rem;
  margin: 2.625rem 0 1.3125rem;
  border-bottom: 1px solid $gray-light;
}

.card {
  border-color: transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  .card-header {
    border-bottom: 0;
    padding: 0.625rem 0.9375rem;
    background-color: transparent;
    .card-title {
      margin-bottom: 0;
      font-size: 1rem;
    }
    a {
      text-decoration: none !important;
    }
  }
}
.card-body {
  padding: 24px; //15px
}
.card-footer {
  padding: 0.625rem 0.9375rem;
}

.card-default {
  border-top-width: 3px;
  border-color: $card-default-border-color;

  .card-header {
    background-color: #fff;
  }
}

.well {
  border: 1px solid $gray;
}

.jumbotron {
  border: 1px solid $gray;
  background-color: #fff;
  @include media-breakpoint-up(md) {
    padding: $jumbotron-padding math.div($jumbotron-padding, 2);
  }
}

.nav-tabs > .nav-item > .nav-link {
  font-weight: bold;
  color: $body-color;
  background-color: #f9fafb;
  margin: 0;
  border: 1px solid var(--border);
  border-radius: 0;
  padding: 10px 20px;
  &.active {
    background-color: var(--card);
    &,
    &:hover,
    &:focus {
      color: var(--foreground);
      border-bottom-color: #fff;
    }
  }
}

// .tab-content {
//   padding: 10px 20px;
//   border-style: solid;
//   border-width: 0 1px 1px 1px;
//   border-color: $gray;
//   .nav-pills  {
//     border: 0;
//     padding: 0;
//   }
//   .p-0 & {
//     padding: 0 !important;
//   }
// }

.btn {
  border-radius: 8px;
  font-size: 13px;
  padding: 10px 12px;
  -webkit-appearance: none;
  outline: none !important; // padding: 0.375rem 1rem;
  // border: none !important;
  @include transition(all 0.1s);
  &.btn-link {
    box-shadow: none;
    border: 0;
  }
  .input-group & {
    font-size: 14px;
    border-color: $input-border-color;
  }
  .input-group .form-control-sm + .input-group-btn & {
    font-size: 0.8125rem;
    padding: 0.3125rem 0.625rem;
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

// Override button styles
.btn {
  &.btn-warning {
    color: #fff;
  }
  &.btn-primary {
    background-color: rgba(127, 86, 217, 1);
  }
  &.btn-outline-warning {
    @include hover-focus-active {
      color: #fff;
    }
  }
}

.btn-secondary {
  background-color: var(--secondary);
  border-color: var(--border);
  color: var(--secondary-foreground);
  &:hover {
    background-color: var(--secondary);
    color: var(--secondary-foreground);
  } // active state override
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    background-color: var(--secondary);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
}

.btn-outline-secondary {
  border-color: $btn-secondary-border !important;
  color: $btn-secondary-color;
  &:hover {
    color: $btn-secondary-color;
    background-color: #f5f5f5 !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($btn-secondary-border, 0.5);
  }
}

span.btn {
  -webkit-appearance: none !important;
}

// FORMS
.form-control {
  box-shadow: 0 0 0 #000 !important;
  font-size: 0.875rem; //14px
  &:focus {
    border-color: $input-focus-border-color;
  }
}

.form-control-sm,
select.form-control-sm {
  font-size: 0.75rem;
  height: 31px;
}

.custom-select {
  -webkit-appearance: none;
}

.custom-control-label {
  line-height: 1.8;
  &:before {
    border: 1px solid $gray;
  }
}

fieldset {
  padding-bottom: 20px;
  border-bottom: 1px dashed #eee;
  margin-bottom: 20px;
  &.last-child,
  &:last-child {
    border-bottom: 0;
  }
  .form-group {
    margin-bottom: 0;
  }
}

.input-group-text {
  font-size: 0.875rem; // 14px
}

@include media-breakpoint-down(sm) {
  // Remove Input Shadows on iPad
  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="password"] {
    -webkit-appearance: none;
  }
}

// TABLES
.table-responsive {
  overflow-y: hidden;
}

.table {
  // Bottom align for column headings
  > thead > tr > th {
    border-bottom-width: 0;
  } // Account for multiple tbody instances
  > tbody + tbody {
    border-bottom-width: 1px;
  }

  .form-control {
    max-width: 100%;
  }
}

.table-bordered {
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 1px;
    }
  }
}

.progress {
  @include box-shadow(0 0 0 #000);
  border-radius: $border-radius-sm;
  border: 1px solid $progress-bg;
  background-color: #fff;
  .progress-bar {
    @include box-shadow(0 0 0 #000);
  }
}

// Popover
// --------------------------------------------------
.popover {
  box-shadow: 0 0 0 #000;
  border-color: #eee;
  border-bottom: 2px solid $gray-light;
  border-radius: $border-radius-sm;
  .popover-title {
    border: 0;
  }
}

// NAV
.nav {
  &.nav-pills {
    .active > a {
      background-color: $primary;
    }
  }
}

// DROPDOWN
.dropdown-menu {
  border-radius: 8px;
  font-size: 14px;
  padding: 0.3125rem 0;
  box-shadow: rgba(0, 0, 0, 0.176) 0px 6px 12px;
  border-color: $dropdown-border-color;
  .dropdown-item {
    line-height: 1.52857143;
    padding: 0.1875rem 1.25rem;
  }
}

.dropdown-header {
  color: #a1a2a3;
}

.navbar-top .navbar-nav > .active > a {
  color: #999;
  &:hover,
  &:focus {
    color: #d1d2d3;
  }
}

.navbar-default .navbar-nav .open .dropdown-menu > li > a {
  color: #666;
  &:hover,
  &:focus {
    color: #222;
  }
}

// CAROUSEL
.carousel {
  .carousel-indicators {
    bottom: 0;
  }
  .carousel-control {
    &.left,
    &.right {
      background-image: none;
    } // Add support for font awesome
    em {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 5;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      margin-left: -10px;
    }
  }
}

// ALERT
@each $color, $value in $theme-colors {
  .alert-#{$color} {
    @include alert-variant($value, $value, #fff);
  }
}

.alert-secondary {
  color: $body-color;
  border-color: $gray-light;
}

// fix for ie9 (dropdwon no showing)
.topnavbar,
.navbar,
.navbar .dropdown-menu {
  filter: none !important;
}
