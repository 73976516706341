// @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700|Poppins:400,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import "style-variables.scss";
@import "./app/common/variables.scss";

@font-face {
  font-display: swap;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/cairo-v28-latin/cairo-v28-latin-300.woff2")
    format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/cairo-v28-latin/cairo-v28-latin-regular.woff2")
    format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/cairo-v28-latin/cairo-v28-latin-500.woff2")
    format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/cairo-v28-latin/cairo-v28-latin-600.woff2")
    format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/cairo-v28-latin/cairo-v28-latin-700.woff2")
    format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 800;
  src: url("../assets/cairo-v28-latin/cairo-v28-latin-800.woff2")
    format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 900;
  src: url("../assets/cairo-v28-latin/cairo-v28-latin-900.woff2")
    format("woff2");
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: var(--background);
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

h3,
h4,
h5 {
  font-weight: 500;
  font-family: "Cairo", sans-serif;
}

h1,
h2,
h3 {
  font-weight: 600;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

// colors-utility

.text-foreground {
  color: var(--foreground) !important;
}

.text-secondary-foreground {
  color: var(--secondary-foreground) !important;
}

.text-primary-foreground {
  color: var(--primary-foreground) !important;
}

.text-muted-foreground {
  color: var(--muted-foreground) !important;
}

.text-sidebar-foreground {
  color: var(--sidebar-foreground) !important;
}

.bg-background {
  background-color: var(--background) !important;
}

.bg-card {
  background-color: var(--card) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.bg-primary-2 {
  background-color: #9c74f5 !important;
}

.bg-muted {
  background-color: var(--muted) !important;
}

.bg-input {
  background-color: var(--input) !important;
}

.bg-sidebar {
  background-color: var(--sidebar) !important;
}

// --------------------------------------------------------------------------------------------

// typography-utility

.text-xs {
  font-size: 12px !important;
}

.text-sm {
  font-size: 14px !important;
}

.text-base {
  font-size: 16px !important;
}

.text-lg {
  font-size: 18px !important;
}

.text-xl {
  font-size: 20px !important;
}

// --------------------------------------------------------------------------------------------

// border
.card-border {
  border: 1px solid var(--border) !important;
  border-radius: 8px !important;
}

.border-main {
  border: 1px solid var(--border) !important;
}

.border-b {
  border-bottom: 1px solid var(--border) !important;
}

.border-t {
  border-top: 1px solid var(--border) !important;
}

.border-r {
  border-right: 1px solid var(--border) !important;
}

.border-l {
  border-left: 1px solid var(--border) !important;
}

.border-primary {
  border: 1px solid var(--border-primary) !important;
}

.border-b-primary {
  border-bottom: 1px solid var(--border-primary) !important;
}

.border-t-primary {
  border-top: 1px solid var(--border-primary) !important;
}

.border-r-primary {
  border-right: 1px solid var(--border-primary) !important;
}

.border-l-primary {
  border-left: 1px solid var(--border-primary) !important;
}

.border-2 {
  border-width: 2px !important;
}
// --------------------------------------------------------------------------------------------

// display
.flex-gap-2 {
  gap: 2px;
}

.flex-gap-4 {
  gap: 4px;
}

.flex-gap-8 {
  gap: 8px;
}

.flex-gap-16 {
  gap: 16px;
}

// -----------------------------------------------------------------------------------------------------

.list-style-none {
  list-style: none !important;
}

// -----------------------------------------------------------------------------------------------------

table {
  overflow: hidden;
  border-radius: 12px;
}

table thead {
  border-radius: 12px;
}

.input-2 {
  border: 1px solid var(--input-2);
}

.divider {
  height: 1px !important;
  background: #64748b !important;
  width: 100%;
  border: none;
}

$base-color: #909fa7;
.topnavbar {
  transition: all 0.5s;
  background: var(--navbar);
  height: 72px;
  // background: linear-gradient(130deg, #7F56D9 17.57%, #400FAC 86.47%);

  .other-database & {
    background: linear-gradient(
      to left,
      $nav-top-bg-start,
      #17bda0,
      $nav-top-bg-start
    );
  }

  .navbar-header {
    width: 140px;
    padding-left: 5em;
  }
}

.topnavbar .navbar-nav > .nav-item {
  .sidebar-toggle {
    font-size: 150%;
    padding-top: 0.7em;
  }

  > .nav-link {
    padding: 0.5rem 0.95rem;
    font-size: 2em;

    &:hover,
    &:focus {
      color: #fea30c;
    }
  }

  &.show > .nav-link {
    &:hover,
    &:focus {
      color: #fea30c;
    }
  }
}

.clickable {
  cursor: pointer;
  width: 100% !important;
  //background-color: var(--card) !important;
}

.swal2-title {
  font-weight: 400 !important;
}

.badge {
  font-weight: 500;
  background-color: rgba(127, 86, 217, 1) !important;
}

.badge-blue {
  background-color: rgba(93, 156, 236, 0.24) !important;
  color: #5d9cec !important;
  padding: 8px;
  border-radius: 20px !important;
}

.badge-green {
  background-color: #17b26a !important;
  padding: 4px 12px;
  border-radius: 20px !important;
}

.badge-secondary {
  background-color: var(--secondary) !important;
  color: var(--secondary-foreground) !important;
  padding: 4px 12px;
  border-radius: 20px !important;
}

.badge-green-light {
  background-color: #ecfdf3 !important;
  border: 1px solid #abefc6 !important;
  color: #067647 !important;
  padding: 6px 8px !important;
  border-radius: 20px !important;
}

.heading-sub-content {
  background: #fff;
  margin: -20px;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.breadcrumb {
  background: none;
  margin-top: -15px;
  padding: 0;
}

.no-focus-style:focus {
  outline: none !important;
  box-shadow: none !important;
}

.swal2-icon {
  font-family: "Source Sans Pro", sans-serif !important;
}

#nprogress {
  .bar {
    background: #17bda0;
    height: 5px;
  }

  .peg {
    box-shadow: 0 0 10px #17bda0, 0 0 5px #17bda0;
  }
}

.spinner-grow-xl {
  width: 4em;
  height: 4em;
}

.animated {
  animation-fill-mode: none !important;
}

.form-control.label-edit {
  padding: 0.375rem 0.375rem;

  &:not(:focus) {
    border-color: transparent;

    &:hover {
      border-color: $gray-400;
    }
  }

  &:focus {
    box-shadow: inset 0 0px 2px $input-focus-border-color !important;
  }
}

.switch.is-invalid ~ .invalid-feedback {
  display: block;
}

.custom-multiselect {
  .multiselect__tags {
    font-size: 12px;
  }
}

.dropdown-item {
  .custom-control-label {
    width: 100%;
  }
}

.content-heading {
  .alert {
    font-size: 0.5em;
  }
}

.brand-logo {
  & > img {
    max-height: 35px;
  }
  background-color: var(--sidebar);
}

.modal {
  .modal-dialog.modal-fullscreen {
    max-height: calc(100vh - 143px);
    width: 100%;
    max-width: calc(100% - 2em);
    height: 100%;
    .modal-content {
      height: 100%;
      .modal-body {
        height: 100%;
        overflow-y: auto;
      }
    }
  }
}

.custom-select {
  font-size: 1em;
}

.content-wrapper {
  transition: padding 0.2s;
}

.offsidebar-open.with-padding .content-wrapper {
  padding-right: 255px;
  @include media-breakpoint-down(lg) {
    padding-right: 20px;
  }
}

.offsidebar {
  padding: 10px;
  z-index: 2;
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.sidebar-nav {
  font-size: 16px;

  > li > a {
    display: flex;
    align-items: center;
    > span.icon {
      width: 1.8em;
      font-size: 1.25em;
      margin-right: 0;
    }
  }
}

.p-0 {
  padding: 0 !important;
}

.sidebar {
  .other-database &:after {
    box-shadow: -1px 0px 3px 1px rgb(23, 189, 160);
    background: none;
  }
}

.custom-switch.b-custom-control-lg,
.custom-checkbox.b-custom-control-lg {
  font-size: 1em;
  .custom-control-label {
    line-height: 2.2;
  }
}

.material-design-icon {
  display: inline-flex;
  align-self: center;
  position: relative;
  height: 1em;
  width: 1em;
  > .material-design-icon__svg {
    height: 1em;
    width: 1em;
    fill: var(--sidebar-icon);
  }
}

.card.with-image {
  .card-body {
    display: flex;
    flex-direction: column;

    .image {
      max-width: 50%;
      align-self: center;
    }
    .text {
      margin-top: 1em;
      flex-grow: 1;
      text-align: center;
    }
  }
}

%highlights {
  transition: background-color 0.2s;
  color: #fff;
}

.highlight {
  &-purple {
    @extend %highlights;
    background-color: #7266ba;
    &:hover {
      background-color: #564aa3;
    }
  }

  &-primary {
    @extend %highlights;
    background-color: #5d9cec;
    &:hover {
      background-color: #2f80e7;
    }
  }
}

.content-wrapper .content-heading h2 {
  // color: #555;
  margin-bottom: 0;
  padding-bottom: 0;
}

.aside-collapsed .sidebar > .sidebar-nav > li > a > span.icon {
  font-size: 2.6em;
  display: block !important;
  width: 100%;
}

.square-toggle {
  display: inline-flex;
  height: 105px;
  width: 105px;
  border: 1px solid $border-color;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  transition: $transition-base;
  box-shadow: #999 2px 2px 3px 0px;
  margin: map-get($map: $spacers, $key: 2);

  &:hover,
  &.active {
    color: #fff;
  }

  &:hover {
    background: $green-light;
    transform: translateY(-3px);
  }

  &.active {
    background: $green;
    box-shadow: #999 2px 2px 3px 0px;
    transform: translateY(-6px);
  }
}

#HW_badge_cont {
  &.HW_visible {
    align-self: center;
  }
}

.list-group-item + .list-group-item {
  border-top-width: $list-group-border-width;
}

.features {
  display: flex;
  .features-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    width: 26px;
    text-align: center;
    height: 26px;
    border-radius: 50%;
    background: #fff;
    color: #2255d0;
    line-height: 26px;
    margin-top: -2px;
    margin-right: 1em;
  }
}

#root,
.layout-fixed {
  height: 100%;
}

.no-caret.dropdown-toggle:after {
  content: none;
}

.marking-bubble {
  width: 30px !important;
  height: 30px !important;
  transition: all 0.2s;
  background: #fff;
}

.marking-bubble svg {
  font-size: 1.2em;
}

.marking-bubble.active {
  transform: translateY(-5px);
  background: rgb(93, 156, 236);
  color: #fff;
}

.box {
  height: 10vh;
  margin: auto;
  position: relative;
  display: block;
}
.speak-bubble {
  height: 6rem;
  width: 15rem;
  background-color: #5d9cec;
  position: inherit;
  border-radius: 15%;
  margin-left: auto;
  color: #fff;
  font-size: 12px;
}

.vertical-center {
  top: 50%;
  transform: translateY(-50%);
}

.left-point {
  width: 0;
  height: 0;
  border-left: 2vh solid transparent;
  border-right: 2vh solid transparent;
  border-top: 10vh solid #5d9cec;
  position: absolute;
  top: -30%;
  left: -10%;
  transform: rotate(120deg);
}

.custom-checkbox.custom-checkbox-lg {
  .custom-control-label {
    line-height: 2.2em;
    &:after,
    &:before {
      width: 1.5em;
      height: 1.5em;
    }
  }
}

.sidebar-nav > li > a.active {
  background-color: var(--secondary-active);
  color: var(--foreground) !important;
}

svg.MuiSvgIcon-root {
  fill: #333;
  height: 1rem;
  width: 1rem;

  @each $name, $colour in $colors {
    .text-#{$name} &,
    &.text-#{$name} {
      fill: $colour;
    }
  }
  .text-white &,
  &.text-white {
    fill: #fff;
  }
}

.react-datetime-picker__wrapper {
  border: 0 !important;
}

.faded {
  opacity: 0.6;
  &.more {
    opacity: 0.4;
  }
  &.less {
    opacity: 0.8;
  }
}

.italic {
  font-style: italic;
}

.notification-type-colour {
  &::before {
    content: " ";
    white-space: pre;
  }

  width: 10px;
  height: 100%;
}

.page-item.active .page-link {
  pointer-events: none;
  z-index: 1;
  color: #fff;
  background-color: #5d9cec;
  border-color: #5d9cec;
}

.tooltip-inner {
  max-width: none;
}

.notificationContent {
  margin-left: 8px;
}

.commentDiv {
  max-width: 400px;
  padding: 7px 15px;
  white-space: normal;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  background: #3481c0;
}

.custom-backdrop-modal .modal-backdrop {
  // background-color: rgba(0, 0, 0, 0.7) !important; /* Unique color for this modal */
  background-color: red !important;
}
.product-filters {
  min-width: 101px;
  min-height: 31px;
}

.visual-filter {
  position: static !important;
}

.visual-filter input {
  border-radius: 8px !important;
  border: 1px solid var(--border);
}

.visual-filter .add-filter {
  cursor: pointer;
  padding: 10px !important;
  border-radius: 8px !important;
  border: 1px solid var(--input-2);
  text-transform: capitalize;
  background: var(--input);
  color: var(--secondary-foreground) !important;
  font-weight: normal !important;
  font-size: 13px;
}
.visual-filter .visual-value input[type="submit"] {
  display: none;
}

.visual-filter input[type="submit"] {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 1rem;
  font-size: 1rem;
  line-height: 1.52857;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #fff;
  border-color: #eaeaea;
  color: #333;
}

.visual-filter .visual-selector.expanded {
  background: #fff;
  position: absolute;
  overflow: hidden;
  border: 1px solid var(--border);
  border-radius: 8px !important;
}

.Form-Custom-Css .react-datepicker-wrapper {
  display: block !important;
  width: 100% !important;
}
.Form-Custom-Css .react-datepicker__input-container input {
  width: 100% !important;
  height: 35px !important;
  border: 1px solid #dde6e9 !important;
  border-radius: 0.25rem !important;
  padding: 0.375rem 1rem;
}
.Form-Custom-Css .form-valid-para {
  color: red;
}
.globalMarking img {
  max-width: 35px;
  border-radius: 0 !important;
  border: 0;
  background: inherit;
}

.editable-element {
  &.not-editing {
    .passed-element {
      // border-bottom: 1px dotted #ccc;
      width: 100% !important;
      border: 1px solid var(--border);
      padding: 10px 14px;
      border-radius: 8px;
      display: inline-block;
      background-color: var(--input);
      font-size: 14px !important;
      color: #738192 !important;
      font-weight: 400 !important;
    }
    .MuiSvgIcon-root {
      font-size: 1.1rem;
      margin-left: map-get($map: $spacers, $key: 1);
    }
  }
}

.roadmap-item-details {
  padding-right: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: #000;
  .MuiSvgIcon-root {
    width: 1rem;
    height: 1rem;
  }
}

.select-custom-design .css-26l3qy-menu {
  margin-top: 400px;
}
.select-custom-design .regions {
  position: absolute;
  bottom: -312px;
  width: 94.5%;
  background: white;
  border-bottom: 4px solid;
  z-index: 999999 !important;
  opacity: 111 !important;
}
.regions-sec {
  width: 96% !important;
  margin-top: "300px";
}
.product-region-sec .css-26l3qy-menu {
  margin-top: 335px !important;
}
.select-custom-design .regionsTemplate {
  bottom: -187px !important;
}
.select-custom-design .regions .btn-primary {
  background: transparent;
  border: 0;
  color: #747474;
  width: 100%;
  text-align: inherit;
  font-size: 14px;
}

//------------------------- Dashboard Css Property Desfine --------------
.dashboard-header {
  background-color: #fff;
  margin-bottom: 10px;
}
.dashboard-header .btnArrow {
  background: transparent;
  border: 0px;
  float: right;
  padding-top: 15px;
}
.dashboard-header .btnClick {
  background: transparent;
  border: 0px;
  color: blue;
  text-decoration: underline;
  padding-top: 0px;
}
.dashboard-header .task-body .btnClick {
  padding-left: 0px !important;
}
.mt-0 {
  margin-top: 0px !important;
}

.dashboard-header .header-task {
  display: flex;
  padding-top: 5px;
}
.dashboard-header .header-task div {
  margin-top: -15px !important;
}
.lh-5 div {
  border-bottom: 0px;
}
.dashboard-header .mb-1 h6 {
  margin-bottom: -1px !important;
}
.dashboard-header button:focus,
.dashboard-header button:active {
  background: transparent !important;
  color: blue !important;
  border: 0px solid !important;
  box-shadow: 0 0 0 0.2rem transparent !important;
}
.dashboard-header .heading-para {
  color: #656667;
  line-height: 0;
  font-size: 14px !important;
}
.dashboard-header .text-muted {
  font-size: 13px !important;
}
.dashboard-header .task-body {
  color: #909fa7 !important;
  font-size: 13px !important;
}
.dashboard-header .cursor {
  cursor: pointer;
  text-decoration: underline;
}
.dashboard-header .card {
  margin-bottom: 0px;
  box-shadow: 0 0 0 !important;
}
.no-parsonal-task {
  text-align: center;
}
.product-region .regionsTemplate {
  bottom: -245px !important;
}
#dropdown-basic::after {
  display: none !important;
}
.herder-flex,
.dashboard-task-map-header .header-task {
  display: flex;
}
.dashboard-task-map-header {
  border-bottom: 1px solid #e0e0e0;
}
.card-header {
  font-size: 16px !important;
  color: var(--foreground) !important;
  padding: 24px !important;
}
.my-tasks {
  font-size: 14px !important;
}
.herder-flex .MuiSvgIcon-root,
.dashboard-task-map-header .MuiSvgIcon-root {
  margin-top: 11px;
  margin-left: 15px;
  margin-right: -10px;
}
.dashboard-top-tasks .cursor,
.dashboard-task-map-header .cursor {
  cursor: pointer;
  text-decoration: underline;
}

//-------------------- cretae notification page properties ---------------------
.create-notify-header {
  padding: 10px;
  padding-bottom: 0px;
}
.create-notify-header .create-notify-header-div {
  border: 1px solid #e0e0e0;
  padding: 20px;
  border-radius: 5px;
}
.ddl-txtColor {
  display: flex;
  height: 20px;
  margin-top: 4px;
}
.ddl-txtColor p {
  height: 25px;
  width: 15px;
  margin-right: 5px;
}
.create-notify-header-div .valid-group p {
  font-size: 80%;
  margin-top: 2px;
  color: #f05050;
}
.ml-20 {
  margin-left: 20px;
}
.product-overview-div {
  line-height: 12px;
  border: 1px solid #cfdbe2;
}
.product-overview-div tr,
.product-overview-div tr td {
  border: 0px !important;
  padding-bottom: 2px;
}
.product-overview-div .complecated-td {
  padding-bottom: 15px;
}
.product-overview-div label {
  padding-top: 5px;
}
.btn-overvirew-legis:disabled,
.btn-overvirew-legis {
  padding: 2px 5px !important;
  border: 1px solid var(--border) !important;
  color: var(--secondary-foreground) !important;
  background-color: transparent !important;
  margin-right: 6px !important;
}

.overview-table-view .space-between-words a {
  padding-right: 5px !important;
}
.tags-bg-property span {
  background-color: #3b3f50;
  margin: 2px;
  padding: 5px 9px 5px 9px;
  color: white;
  border-radius: 50px;
  display: inline-block;
}

.globalMarking .css-1rhbuit-multiValue {
  z-index: 999999 !important;
}

.custom-product-value-div {
  padding: 10px;
}
.validate-para {
  padding: 5px;
  font-size: 11px;
  color: #f05050;
}
.radio-btn-div {
  display: flex;
}
.radio-btn-div .btn-radio-first {
  padding-right: 10px;
}
.product-detail-header {
  padding: 10px;
  border: 1px solid var(--border);
  border-radius: 12px;
  overflow-y: scroll;
}
.product-detail-header .product-detail-body {
  padding-top: 15px;
  height: auto;
  max-height: 300px;
}
.product-detail-body-admin {
  padding-top: 15px;
  height: auto;
  max-height: 500px;
  overflow-y: scroll;
}

.customised-field-span {
  font-weight: bold;
}
.customised-field-para {
  margin-left: 20px;
}
.btn-existing-fields-div {
  padding-top: 10px;
  float: right;
}
.pv-p10-div {
  padding-top: 10px;
}
.pv-p5-div {
  padding-top: 5px;
}
.custom-fields-ddl .css-1pahdxg-control {
  box-shadow: 0px 0px 0px 1px #e0e0e0 !important;
}
.custom-fields-ddl .css-1pahdxg-control:hover,
.custom-fields-ddl .css-yk16xz-control {
  border-color: #e0e0e0 !important;
}
.custom-fields-ddl .css-1wa3eu0-placeholder {
  color: #c5c8d4 !important;
  font-weight: 300 !important;
}
.product-detail-header .date-span,
.product-detail-header .header-div span {
  color: #909fa7 !important;
  font-size: 80%;
  font-weight: 400;
}

.product-detail-header .date-span,
.product-detail-header .header-div span {
  color: #909fa7 !important;
  font-size: 80%;
  font-weight: 400;
}
.product-detail-body-admin .bgColor,
.product-detail-body .bgColor {
  background-color: #f5fafb !important;
}
.pointer-cursor {
  cursor: pointer;
}
.dotted-bottom-border {
  border-bottom: 1px dotted #ccc !important;
}

.content .btn-oval {
  display: flex !important;
  align-items: center;
}

.admin-page-header-div {
  padding: 10px;
  border: 1px solid #cfdbe2;
}
.lightGrayBackground {
  background: #fbfbfbba;
}
.newsSource {
  font-size: 15px;
}
.admin-page-header-div table,
.admin-page-header-div tr,
.admin-page-header-div td {
  border: 0px;
}
.admin-page-header-div .admin-page-table {
  border-bottom: 1px solid #cfdbe2;
}
.product-detail-body .bgColor {
  background-color: #f5fafb !important;
}
.pointer-cursor {
  cursor: pointer;
}

.customTab {
  border: 2px solid #ecedee;
  li {
    border-right: 2px solid #ecedee;
    button {
      background: white !important;
    }
    &:last-child {
      border-right: none;
    }
  }
}

.nav-tabs .nav-item .nav-link.active {
  background-color: var(--card) !important;
}
.editable-element.not-editing .passed-element {
  color: black;
}
.productDetailName {
  margin: 11px 0px 21px 15px;
  color: black;
}

.list-group .list-group-item.active .text-muted {
  color: white !important;
}

.dotted-bottom-border {
  border-bottom: 1px dotted #ccc !important;
}
// thead {
//   background-color: #f2f5f7;
// }
#compliance-entries-over-time_chart_legend_text_0,
#compliance-entries-over-time_chart_legend_text_1,
#compliance-entries-over-time_chart_legend_text_2 {
  font-family: $font-family-sans-serif !important;
  font-size: 12px !important;
}

.globalMarking .css-2b097c-container,
.globalMarking .css-yk16xz-control {
  z-index: 999999 !important;
}
.btn-pv-close {
  float: right;
  background: transparent;
  border: 0px;
  padding: 0px;
}
.btn-pv-close:active,
.btn-pv-close:hover,
.btn-pv-close .MuiSvgIcon-root:active,
.btn-pv-close .MuiSvgIcon-root:hover,
.btn-pv-close .MuiSvgIcon-root {
  background: transparent;
  border: 0px;
}

.custom-input {
  // Your custom styles for the input element
  border-color: #bebebe;

  &:focus {
    border-color: #5d9cec;
  }

  &::placeholder {
    color: #7a7878;
  }
}

/* Define custom styles for the toast popup */
.custom-toast-popup {
  width: 200px; /* Adjust the width as needed */
  max-height: 300px; /* Set a maximum height if necessary */
}

/* Define custom styles for the toast container */
.custom-toast-container {
  width: 100%; /* Ensure the container takes full width of the parent */
  height: auto; /* Allow height to adjust based on content */
}

.MuiPaginationItem-textPrimary.Mui-selected {
  background-color: #7e55d8 !important;
}

.MuiPaginationItem-textPrimary.Mui-selected:hover {
  background-color: #7e55d8 !important;
}

/* syncfusion map */
#country-statuses-map_MapBorder,
#country-statuses-map_MapAreaBorder,
#mapId-map_MapBorder,
#mapId-map_MapAreaBorder,
#maps_control_1_MapBorder,
#maps_control_1_MapAreaBorder {
  fill: transparent !important;
}

#customization-container_CircularGaugeBorder {
  fill: transparent !important;
}

#country-statuses-map_LayerIndex_0 path {
  stroke-width: 1px !important;
  stroke: #fff !important;
}
#mapId-map_LayerIndex_0 path {
  stroke-width: 1px !important;
  stroke: #fff !important;
}

#country-statuses-map_Zooming_KitCollection,
#mapId-map_Zooming_KitCollection,
#product-map_Zooming_KitCollection {
  opacity: 1 !important;
}

#country-statuses-map_Zooming_ToolBar_ZoomIn_Rect,
#country-statuses-map_Zooming_ToolBar_ZoomIn_Path,
#country-statuses-map_Zooming_ToolBar_ZoomOut_Rect,
#country-statuses-map_Zooming_ToolBar_ZoomOut,
#country-statuses-map_Zooming_ToolBar_Reset_Rect,
#country-statuses-map_Zooming_ToolBar_Reset {
  stroke: var(--icon) !important;
}

#mapId-map_Zooming_ToolBar_ZoomIn_Rect,
#mapId-map_Zooming_ToolBar_ZoomIn_Path,
#mapId-map_Zooming_ToolBar_ZoomOut_Rect,
#mapId-map_Zooming_ToolBar_ZoomOut,
#mapId-map_Zooming_ToolBar_Reset_Rect,
#mapId-map_Zooming_ToolBar_Reset {
  stroke: var(--icon) !important;
}

#product-map_Zooming_ToolBar_ZoomIn_Rect,
#product-map_Zooming_ToolBar_ZoomIn_Path,
#product-map_Zooming_ToolBar_ZoomOut_Rect,
#product-map_Zooming_ToolBar_ZoomOut,
#product-map_Zooming_ToolBar_Reset_Rect,
#product-map_Zooming_ToolBar_Reset {
  stroke: var(--icon) !important;
}

// MuiPagination
.MuiPagination-ul {
  border-radius: 8px !important;
  background-color: var(--card) !important;
  border: 1px solid var(--border) !important;
}

.MuiPagination-ul li {
  border-right: 1px solid var(--border) !important;
  min-width: 40px !important;
  min-height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.MuiPagination-ul li:last-child {
  border: none !important;
}

.MuiPaginationItem-root {
  color: var(--foreground) !important;
}

.MuiPaginationItem-icon {
  fill: var(--icon) !important;
}

.MuiPaginationItem-textPrimary.Mui-selected {
  background-color: var(--secondary) !important;
  border-radius: 0px !important;
  margin: 0px !important;
  width: 40px !important;
  height: 40px !important;
}

.MuiPaginationItem-textPrimary.Mui-selected:hover {
  background-color: var(--secondary) !important;
}
// custom-radio

// Variables for colors and sizes
$radio-size: 20px;
$checked-color: #7f56d9;
$unchecked-border-color: #d0d5dd;
$inner-circle-size: 10px;
$border-radius: 8px;

.radio-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .radio-input {
    display: none;
  }

  .custom-radio {
    position: relative;
    display: inline-block;
    width: $radio-size;
    height: $radio-size;
    border: 2px solid $unchecked-border-color;
    border-radius: $border-radius;
    background-color: transparent;
    cursor: pointer;
    vertical-align: middle;
    margin-right: 8px;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: $inner-circle-size;
      height: $inner-circle-size;
      background-color: transparent;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      transition: background-color 0.2s ease;
    }
  }

  .radio-input:checked + .custom-radio {
    border-color: $checked-color;
    background-color: $checked-color;
  }

  .radio-input:checked + .custom-radio::after {
    background-color: #fff;
  }
}

#country-statuses-map_Legend_Group text {
  fill: var(--secondary-foreground) !important;
}

#customization-container_Axis_0_Annotation_0 div {
  color: var(--foreground) !important;
}

#count-by-status-doughnut_chart_legend_translate_g text {
  fill: var(--secondary-foreground) !important;
}

#products-created-over-time-spline_Series_0 {
  stroke: var(--primary) !important;
}

#total-products-over-time-spline_Series_0 {
  stroke: var(--primary) !important;
}

#products-created-over-time-splineSymbolGroup0 ellipse {
  stroke: var(--primary) !important;
}

#total-products-over-time-splineSymbolGroup0 ellipse {
  stroke: var(--primary) !important;
}

#products-created-over-time-splineAxisLabels1 text {
  fill: var(--secondary-foreground) !important;
}

#products-created-over-time-splineAxisLabels0 text {
  fill: var(--secondary-foreground) !important;
}

#total-products-over-time-splineAxisLabels1 text {
  fill: var(--secondary-foreground) !important;
}

#total-products-over-time-splineAxisLabels0 text {
  fill: var(--secondary-foreground) !important;
}

/* For Webkit browsers (Chrome, Safari, Edge) */

/* Scrollbar Track (the part of the scrollbar not covered by the thumb) */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
  height: 12px; /* Height of the scrollbar (for horizontal scrollbar) */
}

/* Scrollbar Handle (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar handle */
  border-radius: 6px; /* Rounded corners for the scrollbar handle */
}

/* Scrollbar Handle on Hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color when hovering over the scrollbar handle */
}

/* Scrollbar Track (the part of the scrollbar track) */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
  border-radius: 6px; /* Rounded corners for the scrollbar track */
}

// chip

.chip-container > div {
  background-color: transparent !important;
  border: 1px solid var(--border) !important;
  border-radius: 8px !important;
}

.chip-container input {
  background-color: var(--card) !important;
  color: var(--foreground);
}

// tasks-tab

.tasks-tab > li {
  flex: 1 !important;
}

.tasks-tab > li > button {
  width: 100% !important;
  padding: 14px 20px !important;
  background-color: transparent !important;
  border: none !important;
}

.tasks-tab > li > button.active {
  border-radius: 12px !important;
  background-color: var(--card) !important;
}

// e-date-range-wrapper

.e-date-range-wrapper {
  border: 1px solid var(--border) !important;
  background-color: var(--input) !important;
  border-radius: 8px !important;
  padding: 4px 12px !important;
}

.e-date-range-wrapper .e-daterangepicker::placeholder {
  color: var(--secondary-foreground) !important;
  font-family: "Cairo", sans-serif !important;
}

.e-date-range-wrapper .e-range-icon {
  color: var(--secondary-foreground) !important;
}

// submenu
.submenu {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
}

.submenu.expand {
  max-height: 500px;
  transition: max-height 0.4s ease-in;
}

.mini-card-hover:hover {
  text-decoration: underline;
}

#dropdown-split-basic {
  background-color: var(--background) !important;
  width: 100px !important;
}

.dropdown .btn.input-2 {
  background-color: var(--background) !important;
}

.react-datetime-picker {
  padding: 0px 6px !important;
}

/* Default style for small screens (col-12) */
.custom-col {
  width: 100%; /* Full width for col-sm-12 */
  padding: 8px; /* Optional padding */
}

.marking-box-tabs .nav-item {
  margin: 4px !important;
}

.marking-box-tabs .nav-item > .nav-link.active {
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1) !important;
  border-radius: 6px !important;
}

/* Medium devices (≥768px) and up (col-md-12) */
@media (min-width: 768px) {
  .custom-col {
    width: 100%;
  }
}

/* Large devices (≥992px) and up (col-lg-12) */
@media (min-width: 992px) {
  .custom-col {
    width: 100%;
  }
}

/* Extra large devices (≥1200px) and up (col-xl-12) */
@media (min-width: 1200px) {
  .custom-col {
    width: 100%;
  }
}

/* Extra-extra large devices (≥1400px) and up (col-xxl-6) */
@media (min-width: 1928px) {
  .custom-col {
    width: 50%; /* 50% width for col-xxl-6 */
  }
}

#count-by-status-doughnut text {
  font-family: "Cairo", sans-serif !important;
}

#country-statuses-map_svg text {
  font-family: "Cairo", sans-serif !important;
}

#compliance-entries-over-time_chart_legend_g text {
  font-family: "Cairo", sans-serif !important;
}

.e-documenteditorcontainer span {
  //font-family: "Cairo", sans-serif !important;
}

#documentation-manager-tabs-tabpane-central .e-tbar-btn-text {
  font-family: "Cairo", sans-serif !important;
}

.e-daterangepicker {
  font-family: "Cairo", sans-serif !important;
}

.e-daterangepicker .e-footer button {
  font-family: "Cairo", sans-serif !important;
}

.custom-placeholder::placeholder {
  font-size: 14px;
}

.MuiPagination-ul {
  justify-content: center !important;
}

.swiper-wrapper {
  transform: none;
}

.border-dashed {
  border-style: dashed !important;
}

.border-blue {
  border: 1px solid #4197ed !important;
}

.swiper-backface-hidden .swiper-slide {
  transform: none !important;
}

.chip-container div div {
  background-color: #f3f5f7 !important;
  border-radius: 16px !important;
  padding: 8px 10px !important;
}

.chip-container div div[role="combobox"] {
  background-color: transparent !important;
  padding: 0px !important;
  border-radius: 0px !important;
}

.chip-container div div[role="listbox"] {
  background-color: transparent !important;
  padding: 0px !important;
  border-radius: 0px !important;
}

table.requirements-table {
  width: 100%;
}

table.requirements-table td,
table.requirements-table th {
  border-right: 1px solid #ddd;
}

table.requirements-table td:first-child,
table.requirements-table th:first-child {
  border-left: 1px solid #ddd;
}

.file-input {
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s;
}

.file-input--active {
  border-color: #007bff;
  background-color: #f8f9fa;
}

.file-input p {
  font-size: 16px;
  color: #666;
}

.progress-bar-container {
  width: 100%;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.progress-bar {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}

.progress-text {
  position: absolute;
  width: 100%;
  text-align: center;
  color: #fff;
}

// radio

.custom-radio input[type="radio"] {
  width: 20px;
  height: 20px;
  border: 2px solid #6a1b9a !important;
  cursor: pointer;
}

.custom-radio input[type="radio"]:checked {
  border-color: #6a1b9a !important;
  background-color: #6a1b9a !important;
}

.custom-radio input[type="radio"]:focus {
  outline: none;
}

.custom-radio label {
  font-size: 14px;
  margin-left: 5px;
  cursor: pointer;
}

// type
.rbt-input-multi.form-control {
  height: 44px !important;
  padding: 6px 12px !important;
  line-height: 30px !important;
}

.rbt-input-wrapper {
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
}

.rbt-token.rbt-token-removeable {
  display: flex !important;
  gap: 6px !important;
  align-items: center !important;
  border: 1px solid var(--border) !important;
  border-radius: 8px !important;
  padding: 0px 4px !important;
}
.rbt-close-content {
  line-height: normal !important;
}

//

.text-black-3 {
  color: #4a5467 !important;
}
