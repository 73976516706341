/* ========================================================================
     Component: dropzone
 ========================================================================== */

.vue-dropzone {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @include media-breakpoint-down(sm) {
        text-align: center;
    }

    .dz-preview {
        position: relative;
        margin-bottom: 1rem;
        padding: .5rem;
        border: 1px solid $gray;
        @include media-breakpoint-up(sm) {
            margin-right: .5rem;
            width: 9rem;
        }
    }

    &.dz-started .dz-message {
        display: none;
    }

    .dz-default.dz-message {
        font-size: 35px;
        text-align: center;
    }


    .dz-error-mark,
    .dz-success-mark {
      position: absolute;
      display: none;
      top: 2.5rem;
      left: 2.5rem;
    }
    .dz-error .dz-error-mark {
        display: block;
    }

    .dz-image > img {
        max-width: 100%;
        display: block;
    }

}
