.marketplace-item {

    &:hover, &:focus {
        .item-image {
            transform: scale(1.1);
        }

        .item-image-container {
            &::after {
                background: rgba(0,0,0,0.4);
            }
        }

        .overlay-btn {
            &:hover {
                color: unset;
            }

            opacity: 1;
        }
    }

    * {
        transition: all 0.2s;
    }

    .item-image-container {
        overflow: hidden;

        &, &::after {
            background: rgba(0,0,0,0);
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            content: ""
        }


        .item-image {
            width: 100%;
            height: 100%;
        }
    }

    .overlay-btn {
        opacity: 0;
        z-index: 2;
        color: #fff;
    }

    .cta-btn {
        text-align:center;
    }
}
