$font-family-sans-serif: "Open Sans", "Poppins", "sans-serif";
$body-bg: #ffffff;
$nav-top-bg: #337ab7;
$nav-top-bg-start: #337ab7;
$nav-top-bg-end: #3aa0e4;

:root {
  --background: #f8fafc;
  --foreground: #334155;
  --card: #fff;
  --card-foreground: #333333;
  --primary: #bb86fc;
  --primary-foreground: #ffffff;
  --secondary: #eef1f4;
  --secondary-active: #eef1f4b8;
  --secondary-foreground: #64748B;
  --quaternary: #fff;
  --muted: #f8f9fa;
  --muted-foreground: #6c757d;
  --border: #eaecf0;
  --border-primary: #CBD5E1;
  --icon: #475569;
  --input: #FFF;
  --input-2: #D0D5DD;
  --sidebar: #3f3079;
  --navbar: #e3e7f0;
  --sidebar-foreground: #F1F5F9;
  --sidebar-icon: #F1F5F9;
}

[data-theme="dark"] {
  --background: #0e121b;
  --foreground: #F1F5F9;
  --card: #020617;
  --card-foreground: #f0f0f0;
  --primary: #bb86fc;
  --primary-foreground: #000000;
  --secondary: #161b26;
  --secondary-foreground: #94A3B8;
  --quaternary: #020617;
  --muted: #2c2c2c;
  --muted-foreground: #bbbbbb;
  --border: #334155;
  --border-primary: #64748B;
  --icon: #f4ebff;
  --input: #161B26;
  --input-2: #334155;
  --sidebar: #020617;
  --navbar: #020617;
  --sidebar-foreground: #F1F5F9;
  --sidebar-icon: #F1F5F9;

}
