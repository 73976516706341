.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  display: flex;
  align-items: center;
  border: 1px solid white;
  border-radius: 6px;
  justify-content: center;
  z-index: 1050; /* Ensure it overlays on the modal content */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #007bff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.customBadge {
  background-color: red !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-alert-success {
  background-color: white;
  color: #17a638;
  font-weight: bold;
  font-size: 25px;
}
.fixed-button {
  position: fixed;
  right: -31px;
  bottom: 500px;
  padding: 10px 20px;
  background-color: #4716b1;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000;
  transform: rotate(270deg);
}

.fixed-button:hover {
  background-color: #250569;
}
.custom-alert-error {
  background-color: white;
  color: #c21919;
  font-weight: bold;
  font-size: 25px;
}

.bootAlertStyle {
  background: white;
  border: unset;
}

.overlay.success {
  background: white;
}

.overlay.error {
  background: white;
}

.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #ccc;
  padding: 20px;
  cursor: pointer;
}

.dropzone-active {
  border-color: #000;
}

.dropzone-icon {
  width: 24px;
  height: 24px;
}

.preview-section {
  margin-top: 40px;
}

.preview-header {
  display: flex;
  gap: 20px;
  align-items: center;
}

.preview-header span {
  font-size: 12.5px;
}
.preview-title {
  font-size: 24px;
  font-weight: 600;
}
.dropzoneUploadStyle {
  text-align: center;
  margin-bottom: 12px;
}
.remove-all-button,
.upload-button {
  margin-top: 4px;
  font-size: 11px;
  font-weight: bold;
  border: 1px solid;
  border-radius: 4px;
  padding: 4px 8px;
  transition: all 0.3s;
}
.remove-all-button {
  background-color: #f00;
  color: #fff;
  margin-top: 7px !important;
  padding: 10px 8px !important;
}

.remove-all-button:hover {
  background-color: rgba(255, 0, 0, 0.836);
}

.upload-button {
  margin-left: auto;
}

.upload-button:hover {
  background-color: #800080;
  color: #fff;
}

.accepted-files {
  margin-top: 40px;
  border-bottom: 1px solid;
  padding-bottom: 12px;
  font-size: 16px;
}

.accepted-files-list {
  margin-top: 24px;
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
  padding: 0 !important;
}

.accepted-file-item {
  position: relative;
  height: 75px;
  width: 60px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  list-style: none;
}

.paperClipIcon {
  margin: 2px 6px 2px 6px;
}

.accepted-general-file-item {
  position: relative;
  height: 25px;
  width: 220px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  list-style: none;
  margin-right: 40px !important;
}
.accepted-file-item img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 8px;
}

.remove-file-button {
  position: absolute;
  top: -12px;
  right: -12px;
  width: 28px;
  height: 28px;
  background-color: black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}

.remove-file-button:hover {
  background-color: #fff;
}

.remove-file-button svg {
  fill: #fff;
  transition: all 0.3s;
}

.remove-file-button:hover svg {
  fill: black;
}

.reference {
  max-width: 350px;
}
.referencePageInput {
  margin-top: 10px;
}

.custom-dropzone {
  margin-top: 20px;
  background-color: #f3f5f7 !important;
  border-radius: 12px !important;
  border-width: 2px !important;
  border-color: #ccd6e3 !important;
}

.file-name {
  margin-top: 8px;
  color: #888;
  font-size: 12px;
  font-weight: 500;
}

.dropzone-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dropzone-content p {
  margin-bottom: 0 !important;
}

.rejected-files {
  margin-top: 96px;
  border-bottom: 1px solid;
  padding-bottom: 12px;
  font-size: 16px;
}

.rejected-file-item {
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.rejected-file-name {
  margin-top: 8px;
  color: #888;
  font-size: 14px;
  font-weight: 500;
}

.rejected-file-errors {
  font-size: 12px;
  color: #f00;
}

.remove-rejected-button {
  margin-top: 4px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  border: 1px solid;
  border-radius: 4px;
  padding: 4px 8px;
  transition: all 0.3s;
}

.remove-rejected-button:hover {
  background-color: #f00;
  color: #fff;
}
